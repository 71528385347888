<template>
    <div>
        <hero/>
        <contents/>
    </div>
</template>

<script>
    export default {
        components: {
            Hero: () => import ('@/components/about/Hero'),
            Contents: () => import('@/components/about/Contents')
        },
        mounted(){
            this.$store.dispatch('app/setLink', 1)
            },
        created() {

        }
    }
</script>

